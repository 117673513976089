import Chooice from "./chooice ";
import Footer from "./footer";
import "./Home.css";
import { FaWarehouse, FaQuoteLeft,FaPlaneDeparture,FaHome } from "react-icons/fa";
import React, { useEffect, useState } from 'react';
import { motion} from 'framer-motion/dist/framer-motion';
import { Link } from "react-router-dom";
import AOS from 'aos';
import 'aos/dist/aos.css'
import CountUp from "react-countup";
import ScrollTrigger from "react-scroll-trigger"
import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";



const Home = () => {
  const [counterOn,setCounterOn]=useState(false)

  useEffect(()=>{
    AOS.init({duration: 1500});
  }, [])
  var settings = {
    dots: true,
    infinite: false,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 4,
    initialSlide: 0,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
          dots: true
        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      }
    ]
  };

  useEffect(()=>{
    AOS.init({duration: 1500});
  }, [])
  const typingContainer = {
    hidden: { opacity: 0},
    show: { 
      opacity: 1,
      transition:{
        staggerChildren: 0.14,
      }}
  }
  const typingText = {
    hidden: { opacity: 0, y:"-20px"},
    show: { 
      opacity: 1, 
      y:"0",
      transition:{
        ease:'easeInOut',
      }
    }
  }
  const explainProduct = {
    hidden: { opacity: 0, y:"-20px"},
    show: { 
      opacity: 1, 
      y:"0", 
      transition:{
        delay:2.2,
        ease:"easeInOut"
      }
    }
  }
  // const buttonProduct = {
  //   hidden: { opacity: 0, scaleX:0},
  //   show: { 
  //     opacity: 1, 
  //     scaleX:1,
  //     transition:{
  //       duration:.8,
  //       delay:2.3,
  //       type:'tween'
  //     }
  //   }
  // }
  const imgProduct1 = {
    hidden: { 
      opacity: 0,
      y:'400px',
      width:'80px'
    },
    show: { 
      opacity: 1,
      y:0,
      width:'250px',
      transition:{
        duration:1,
        delay:2.5,
        ease:"easeInOut"
      }
    }
  }
  const imgProduct2 = {
    hidden: { 
      opacity: 0,
      y:'400px',
      x:'50px',
      width:'80px'
    },
    show: { 
      opacity: 1,
      y:0,
      x:0,
      width:'250px',
      transition:{
        duration:1,
        delay:3.4,
        ease:"easeInOut"
      }
    }
  }
  
  const starAnimation = {
    hidden:{
      opacity:0,
      x:'-50px'
    },
    show: { 
      opacity:1,
      x:0,
      transition:{
        staggerChildren:.4,
        delayChildren:3.8,
      }
    }
  }
  const starItem = {
    hidden: { 
      opacity:0,
      x:'-100px',
      
    },
    show: { 
      opacity:1,
      x:0,
      transition:{
        duration:1.2,
        ease:"easeIn"
      }
    }
  }
  
  
 return (
        <div className="home-page">   
          <header>
          
        <section className='section'>
        <div className='left'>
          <motion.h1 className='text' variants={typingContainer} initial="hidden" animate="show">
            {
              Array.from("Decka Movers").map((word,i) => (
              <motion.span key={i} variants={typingText}>{word}</motion.span>
              ))
            }
          </motion.h1>
         
          <div className='explain-product'>
            <motion.p variants={explainProduct} initial="hidden" animate="show">
            Decka mover is a premier moving company based in Nairobi. The company aims to be the leading and most trusted moving and logistics partner in Kenya
            </motion.p>
            <motion.p variants={explainProduct} initial="hidden" animate="show">
              " Moving:   Anything,  Anytime,  Anyplace "
           </motion.p>
            <div className="learn">
            <Link to="./About" >Learn More...</Link> 
            </div>
          </div>
          
          <motion.div className='star-square' variants={starAnimation} initial="hidden" animate="show">
            <motion.img src={require("./img/package1.jpg")} alt="star-square" variants={starItem} />
            <motion.img src={require("./img/lorry2.jpeg")} alt="star-square" variants={starItem} />
            <motion.img src={require("./img/sofa.jpeg")} alt="star-square" variants={starItem} />
          </motion.div>
        </div>
        <div className='right'>
          <motion.img src={require("./img/lorry1.jpeg")} alt="nature" className='img1' variants={imgProduct1} initial="hidden" animate="show" />
          <motion.img src={require("./img/package1.jpg")} alt="coffee" className='img2' variants={imgProduct2} initial="hidden" animate="show" />
        </div>
      </section>
     
    </header>

    <Chooice />
    <section class="section__container benefits__container">
      <h2 class="section__header">Our Services</h2>
      <div class="benefits__grid">
        <div class="benefits__card" data-aos="zoom-in">
        <h4>Local Move</h4>
        <span><FaHome style={{ fontSize: 40 }}/></span>
          <div class="benefits__content">
            <p>
            Moving within Kenya? Our team of experienced professionals will handle 
            every aspect of your local move. From carefully packing your belongings to transporting them safely to your new residence, we ensure a stress-free moving experience. 
            </p>
           
          </div>
        </div>
        <div class="benefits__card" data-aos="zoom-in">
        <h4>International Move</h4>
        <span><FaPlaneDeparture style={{ fontSize: 40 }}/></span>
          <div class="benefits__content">
            <p>
            Relocating to another country? Our international moving services are tailored to meet the unique challenges of cross-border relocations. We have the expertise to navigate customs regulations, logistics, and paperwork, making your international move as smooth as possible.
            </p>
            
          </div>
        </div>
        <div class="benefits__card" data-aos="zoom-in">
          <h4>Storage and Packaging</h4>
          <span><FaWarehouse style={{ fontSize: 40 }}/></span>
          <div class="benefits__content">
            <p>
            If you need temporary storage for your belongings, our secure storage facilities are at your disposal. Whether you require short-term or long-term storage, your items will be kept safe and protected until you're ready to have them delivered
            </p>
            
          </div>
        </div>
      </div>
    </section>

    <section class="section__container experience__container">
      <div class="experience__image" data-aos="fade-right">
        <img src={require("./img/lorry2.jpeg")} alt="experience" />
      </div>
      <div class="experience__content"  data-aos="fade-up">
        <h2 class="section__header">Who we are</h2>
        <p class="experience__description">
        Decka Movers takes pride in its team of highly skilled and trained professionals 
        who possess deep knowledge of the logistics and moving industry. 
        <br />We strives to deliver timely and efficient moving services, ensuring that customers' belongings are handled with the utmost care and delivered to their new destinations safely
        </p>
        
            <Link to="./About" ><button>Learn more</button></Link> 
          
            
        <div class="experience__features">
          <div class="experience__col">
           <ScrollTrigger onEnter={()=>setCounterOn(true)} onExit={()=>setCounterOn(false)}>
            <h3>
              {counterOn &&<CountUp start={0} end={6} duration={2} delay={0} />}
              
            </h3>
            </ScrollTrigger>
            <p>Years<br />experience</p>
          </div>
          <div class="experience__col">
            <ScrollTrigger onEnter={()=>setCounterOn(true)} onExit={()=>setCounterOn(false)}>
            <h3>
              {counterOn &&<CountUp start={0} end={300} duration={2} delay={0} />}
              +
            </h3>
            </ScrollTrigger>
            <p>Movers<br /> Completed</p>
          </div>
          <div class="experience__col">
           <h3>4</h3>
            <p>Awards<br />gained</p>
          </div>
          
        </div>
      </div>
    </section>

    <section class="section__container experience__container">
          
          <div className="all-review">
          <h2 class="section__header">Our Clients</h2>
          <div class="experience__col" data-aos="zoom-in">
                 <p>We have moved over</p>
                 <h3>50+</h3>
                 <p>Companies</p>
               </div>
          </div>
      
           <div className="carousel">
           <Slider {...settings}>
             <div className="box">
                <img src={require("./img/client1.png")} alt="experience" />
             </div>
             <div className="box">
               <img src={require("./img/client3.jpg")} alt="experience" />
             </div>
             <div className="box">
              <img src={require("./img/client6.png")} alt="experience" />
             </div>
             <div className="box">
              <img src={require("./img/client2.jpg")} alt="experience" />
             </div>
             <div className="box">
             <img src={require("./img/client5.jpg")} alt="experience" />
             </div>
             <div className="box">
             <img src={require("./img/client4.png")} alt="experience" />
             </div>
             <div className="box">
             <img src={require("./img/client7.png")} alt="experience" />
             </div>
             </Slider>
           </div>
           
         
          </section>
     




    <section class="section__container process__container">
    
      <div class="process__content"  data-aos="fade-up"> 
        <h2 class="section__header">Our Moving Process</h2>
        <div class="process__grid">
          <div class="number">01</div>
          <h4>Pre-Move Phase:</h4>
          <span></span>
          <p>
          You can request a moving quote by filling out our online form on our website or by contacting our customer service team. We will gather necessary information to provide you with an accurate estimate.
          </p>
          <div class="number">02</div>
          <h4>On-Moving Day:</h4>
          <span></span>
          <p>
          A comprehensive inventory list is created, detailing each item and its condition before packing.
          Upon arrival at the destination, our team unpacks and assembles furniture, placing items according to the client's preferences.
          </p>
          <div class="number">03</div>
          <h4>Post-Move Phase:</h4>
          <span class="last-child"></span>
          <p>
          Our team conducts a thorough inspection to ensure all items have been safely delivered and are in good condition.
          </p>
        </div>
        <div className="experience__content">          
          <Link to="./About" ><button>Learn more</button></Link>            
        </div>
      </div>
      <div class="experience__image"  data-aos="fade-left">
        <img src={require("./img/fridge.jpeg")} alt="experience" />
      </div>
    </section>

    <section class="section__container customer__container">
      <div class="customer__nav">
        <h2 class="section__header">What our customers are saying</h2>
        <div class="customer__nav__icons">
          <span><i class="ri-arrow-left-line"></i></span>
          <span><i class="ri-arrow-right-line"></i></span>
        </div>
      </div>
      <div class="customer__grid">
        <div class="customer__card" data-aos="flip-left">
          <span><FaQuoteLeft  style={{ fontSize: 30 }} /></span>
          <h4 class="customer__title">Overall, we can't recommend Decka Mover enough.</h4>
          <p class="customer__description">
           They turned what could have been a stressful experience into a smooth and efficient move.Not to mention, their pricing was fair and transparent,no hidden fees or surprises.
          </p>
          <div class="customer__content">
            <span><img src={require("./img/client8.png")} alt="user" /></span>
            <div class="customer__details">
              <h4>John Ader</h4>
              <p>Customer</p>
            </div>
          </div>
        </div>
        <div class="customer__card" data-aos="flip-right">
          <span><FaQuoteLeft style={{ fontSize: 30 }}/></span>
          <h4 class="customer__title">
          The moving crew they sent was outstanding.
          </h4>
          <p class="customer__description">
          The moving crew they sent was outstanding. They were courteous, efficient, and handled our fragile items with such care. We were worried about our antique furniture, but they treated it like their own.
          </p>
          <div class="customer__content">
            <span><img src={require("./img/client8.png")} alt="user" /></span>
            <div class="customer__details">
              <h4>Valarie Ann</h4>
              <p>Customer</p>
            </div>
          </div>
        </div>
      </div>
    </section>



         <Footer />
         </div> 
        );
}
 
export default Home;