import React, { useState } from 'react';
import { useHistory, Link } from "react-router-dom";
import { FaArrowLeft } from "react-icons/fa";
import "./Data/form.css";

const MultiStepForm = () => {
  const [step, setStep] = useState(1);
  const [formDetail, setFormDetail] = useState({
    name: '',
    phoneNumber: '',
    email: '',
    movingFrom: '',
    movingTo: '',
    movingDate: '',
    bedroomCount: '1',
  });
  const [errors, setErrors] = useState({});
  const history = useHistory();

  const isStepValid = () => {
    const { name, phoneNumber, email, movingFrom, movingTo, movingDate } = formDetail;
    let newErrors = {};

    if (step === 1) {
      if (!name) newErrors.name = 'Name is required.';
      if (!phoneNumber.match(/^\d{10}$/)) newErrors.phoneNumber = 'Phone number is not valid.';
      if (!email.match(/^[\w-]+(\.[\w-]+)*@[\w-]+(\.[\w-]+)+$/)) newErrors.email = 'Email is not valid.';
    } else if (step === 2) {
      if (!movingFrom) newErrors.movingFrom = 'Moving from is required.';
      if (!movingTo) newErrors.movingTo = 'Moving to is required.';
    } else if (step === 3) {
      if (!movingDate) newErrors.movingDate = 'Moving date is required.';
    }

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleNext = () => {
    if (isStepValid()) {
      setStep(step + 1);
    }
  };
 
  const handlePrevious = () => {
    setStep(step - 1);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const response = await fetch('/api/metadata2', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(formDetail),
      });
      if (response.ok) {
        history.push("/success");
      } else {
        console.error('Error submitting form:', response.status);
        // Handle errors, maybe show an error message to the user
      }
    } catch (error) {
      console.error('Error submitting form:', error);
      // Handle errors, maybe show an error message to the user
    }
  };
 
  return (
    <section className='section_choices'>
      <div className="choice">
        <div className="ui-message-success">Get a booking now!</div>
        <div className="ui-message-success-2">
          Step {step} of 3
        </div>
        <div className="ui-message-success-2">
          <p><FaArrowLeft /> <Link to="/about">Go back</Link></p>
        </div>
       
        <div className="icon">
          <form>
            {step === 1 && (
              <div className='icon_move'>
                <label>Name</label>
                <input
                  type="text"
                  value={formDetail.name}
                  onChange={(e) => setFormDetail({ ...formDetail, name: e.target.value })}
                />
                <span className="error">{errors.name}</span>

                <label>Phone Number</label>
                <input
                  type="text"
                  value={formDetail.phoneNumber}
                  onChange={(e) => setFormDetail({ ...formDetail, phoneNumber: e.target.value })}
                />
                <span className="error">{errors.phoneNumber}</span>

                <label>Email</label>
                <input
                  type="text"
                  value={formDetail.email}
                  onChange={(e) => setFormDetail({ ...formDetail, email: e.target.value })}
                />
                <span className="error">{errors.email}</span>
              </div>
            )}

            {step === 2 && (
              <div className='icon_move'>
                <label>Moving From</label>
                <input
                  placeholder=' (eg.Nairobi/Langata/South C)'
                  type="text"
                  value={formDetail.movingFrom}
                  onChange={(e) => setFormDetail({ ...formDetail, movingFrom: e.target.value })}
                />
                <span className="error">{errors.movingFrom}</span>

                <label>Moving To</label>
                <input
                  placeholder=' (eg.Nairobi/Langata/South C)'
                  type="text"
                  value={formDetail.movingTo}
                  onChange={(e) => setFormDetail({ ...formDetail, movingTo: e.target.value })}
                />
                <span className="error">{errors.movingTo}</span>
              </div>
            )}

            {step === 3 && (
              <div className='icon_move'>
                <label>Moving Date</label>
                <input
                  type="date"
                  value={formDetail.movingDate}
                  onChange={(e) => setFormDetail({ ...formDetail, movingDate: e.target.value })}
                />
                <span className="error">{errors.movingDate}</span>

                <label>Bedroom Count</label>
                <select
                  value={formDetail.bedroomCount}
                  onChange={(e) => setFormDetail({ ...formDetail, bedroomCount: e.target.value })}
                >
                  <option value="B">studio/bedsitter</option>
                  <option value="1">1 Bedroom</option>
                  <option value="2">2 Bedroom</option>
                  <option value="3">3 Bedroom</option>
                  <option value="4">4 Bedroom</option>
                </select>
              </div>
            )}
            <div className="my_choice more">
              <div className="icon too">
                <button type="button" onClick={handlePrevious} disabled={step === 1}>
                  Previous
                </button>
              </div>
              <div className="icon too">
                {step < 3 && <button type="button" onClick={handleNext}>Next</button>}
                {step === 3 && <button type="button" onClick={handleSubmit}>Submit</button>}
              </div>
            </div>
          </form>
        </div>
      </div>
    </section>
  );
};

export default MultiStepForm;
