import React from 'react';
import './LoadingAnimation.css'; // Import your CSS file for styling

const LoadingAnimation = () => {
  return (
    <div className="loading-container">

<div className='section_choice'>
      <div className="choice">
        <h2 className='section__header-ch'>Get your Quote !</h2>
        <span className='accordion__title'>click to pick your preffered move</span>
        <div className="loading-spinner"> </div>
        <span className='title'>Loading please wait...</span>
     </div>
      </div>
      
    </div>
  );
};

export default LoadingAnimation;
