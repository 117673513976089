// App.js
import React, { useState } from 'react';
import ChoiceSection from './ChoiceSection';
import LoadingAnimation from './LoadingAnimation';
import Office from './office';
import MultiStepForm from './MultiStepForm';

const App = () => {
  const [selectedOption, setSelectedOption] = useState(null);
  const [isLoading, setLoading] = useState(false);

  const handleChoiceSelect = (option) => {
    setLoading(true);

    // Simulate an API call or any asynchronous operation
    setTimeout(() => {
      setSelectedOption(option);
      setLoading(false);
    }, 1200);
  };

  return (
    <div>
      {isLoading ? (
        <LoadingAnimation />
      ) : selectedOption === null ? (
        <ChoiceSection onSelect={handleChoiceSelect} />
      ) : selectedOption === 'multiStep' ? (
        <Office />
      ) : (
        <MultiStepForm />
      )}
    </div>
  );
};

export default App;
