import React from 'react';
import "./service.css";
import { FaHome, FaBuilding} from "react-icons/fa";
import AOS from 'aos';
import 'aos/dist/aos.css'
import { useEffect } from 'react';
import "./Home.css";

const ChoiceSection = ({ onSelect }) => {
  useEffect(()=>{
    AOS.init({duration: 1500});
  }, [])


  return (
    <div className='section_choice'>
      <div className="choice">
        <h2 className='section__header-ch'>Request a moving quote </h2>
        <span className='accordion__title'>click to pick your preffered move</span>
        <div className="my_choice more">
          <div className="icon2" data-aos="zoom-in-up">
          <div className="icon-container" >
            <span><FaHome style={{ fontSize: 50 }} onClick={() => onSelect('singleStep')}>Multi-Step Form</FaHome></span>
           </div>
           <p>Home move</p>
           </div>
           <div className="icon2" data-aos="zoom-in-up">
           <div className="icon-container">
             <span><FaBuilding style={{ fontSize: 50 }} onClick={() => onSelect('multiStep')}>Single-Step Form</FaBuilding></span>         
           </div>
           <p>Office move</p>
           </div>
        </div>
      </div>
      
    </div>
  );
};

export default ChoiceSection;