import Footer from "./footer";
import "./service.css";
import React from 'react';


const Services = () => {
  
 
    return ( 
   <div className="service">
   
  <section class="section__container process__container">        
         <div class="process__content" data-aos="fade-up">
           <h2 class="section__header">Local Services:</h2>
           <p class="experience__description">
          <strong>1.Local Moves:</strong> <p>Moving within Kenya? Our team of experienced professionals will handle every aspect of your local move. From carefully packing your belongings to transporting them safely to your new residence, we ensure a stress-free moving experience. Our local moving services are designed to save you time and effort while safeguarding your possessions.</p> 

          <strong>2.Furniture Disassembly and Assembly:</strong>  <p>Bulky furniture can be a challenge to move. Our experts are proficient in disassembling and reassembling your furniture, ensuring it arrives at your new location in perfect condition. You won't have to worry about the complexities of assembling furniture – we've got it covered. </p> 

          <strong>3.Packing and Unpacking:</strong>  <p> Our skilled packers are trained to handle your belongings with care, ensuring they are appropriately packed and protected for the journey. We use high-quality packing materials to safeguard your items from damage during transit. Upon reaching your new destination, our team can also assist with unpacking, helping you settle into your new home quickly.</p> 
           </p>           
         </div>
   
          <div class="experience__images" data-aos="zoom-in">
            <img src={require("./img/box.jpg")} alt="experience" />
          </div>
        </section>

        <section class="section__container process__container">        
         <div class="process__content" data-aos="fade-up">
           <h2 class="section__header">International and storage Service</h2>
          
           <p class="experience__description">
          <strong>1.International Moves:</strong> <p>Relocating to another country? Our international moving services are tailored to meet the unique challenges of cross-border relocations. We have the expertise to navigate customs regulations, logistics, and paperwork, making your international move as smooth as possible. Rest assured that your belongings will be packed, shipped, and delivered with the utmost care and efficiency.</p>
          <strong>2.Storage Solutions: </strong>  <p>If you need temporary storage for your belongings, our secure storage facilities are at your disposal. Whether you require short-term or long-term storage, your items will be kept safe and protected until you're ready to have them delivered.</p>
          <strong>3.Specialty Items Handling</strong> <p>Have valuable, fragile, or specialized items? Our team is trained in handling delicate possessions such as artwork, antiques, pianos, and more. We use appropriate techniques and equipment to ensure these items are transported and delivered securely. </p>
         
           </p>           
         </div>
   
         <div class="experience__image" data-aos="zoom-in">
            <img src={require("./img/fridge.jpeg")} alt="experience" />
          </div>
        </section>
        <section class="section__container process__container">        
         <div class="process__content" data-aos="fade-up">
           <h2 class="section__header">Auxilary services:</h2>
          
           <p class="experience__description">
          <strong>1.Transportation Services:</strong> <p>Our fleet of modern and well-maintained vehicles is equipped to handle moves of any size. We prioritize the safety of your possessions during transit, and our drivers are skilled in navigating various road conditions to ensure a secure journey.</p> 

          <strong>2.Insurance Coverage:</strong> <p>Your peace of mind is important to us. We offer comprehensive insurance options to provide financial protection in the unlikely event of loss or damage during the moving process. Our team will guide you through the insurance options available to you.</p> 
       
          <p>When you choose Decka movers, you're choosing a moving partner that is dedicated to delivering exceptional service from start to finish. With our extensive range of services, you can focus on the excitement of starting anew while we take care of the logistics. Contact us today to discuss your moving needs and let us help you plan a successful move, whether it's across the street or around the world.</p> 

        

        
           </p>           
         </div>
   
         <div class="experience__image" data-aos="zoom-in">
            <img src={require("./img/lorry1.jpeg")} alt="experience" />
          </div>
        </section>



    
        <Footer />
        </div> );
   
}
 
export default Services;