
import Footer from "./footer";
import "./Home.css";
import React from 'react';
// import Marquee from 'react-fast-marquee';
import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import AOS from 'aos';
import 'aos/dist/aos.css'
import { useEffect,useState } from 'react';
import CountUp from "react-countup";
import ScrollTrigger from "react-scroll-trigger"
import { FaRegCheckCircle, FaHandshake,FaRegClock } from "react-icons/fa";

const About = () => {

  const [counterOn,setCounterOn]=useState(false);


  useEffect(()=>{
    AOS.init({duration: 1500});
  }, [])
  var settings = {
    dots: true,
    infinite: false,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 4,
    initialSlide: 0,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
          dots: true
        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      }
    ]
  };
  return ( 
  <div className="about-page">
    <section class="section__container process__container">
         
      <div class="process__content" data-aos="fade-up">
        <h2 class="section__header">About us</h2>
        <strong>History:</strong>
        <p class="experience__description">
          Decka mover is a premier moving company based in Nairobi, Kenya, was founded in
           2017 with the vision of revolutionizing the moving and logistics industry in the region.
           The company was established by a group of forward-thinking entrepreneurs who recognized the
           need for a reliable, efficient, and customer-centric moving service in Kenya.
        </p>
        <p class="experience__description">
        In its early days, we started as a small team of dedicated professionals
         who were passionate about providing seamless and stress-free relocation experiences for 
         individuals, families, and businesses. Through hard work, dedication, and a commitment to 
         excellence, the company quickly gained a reputation for its exceptional service, attention to
         detail, and personalized approach to every move.
        </p>
        <div class="experience__features">
          
          <div class="experience__col">
           <ScrollTrigger onEnter={()=>setCounterOn(true)} onExit={()=>setCounterOn(false)}>
            <h3>
              {counterOn &&<CountUp start={0} end={6} duration={2} delay={0} />}
              
            </h3>
            </ScrollTrigger>
            <p>Years<br />experience</p>
          </div>
          <div class="experience__col">
            <ScrollTrigger onEnter={()=>setCounterOn(true)} onExit={()=>setCounterOn(false)}>
            <h3>
              {counterOn &&<CountUp start={0} end={300} duration={2} delay={0} />}
              +
            </h3>
            </ScrollTrigger>
            <p>Movers<br /> Completed</p>
          </div>
          <div class="experience__col">
            <ScrollTrigger onEnter={()=>setCounterOn(true)} onExit={()=>setCounterOn(false)}>
            <h3>
              {counterOn &&<CountUp start={0} end={4} duration={2} delay={0} />}
              
            </h3>
            </ScrollTrigger>
            <p>Awards<br />gained</p>
          </div>
      </div>
      </div>

      <div class="experience__image" data-aos="fade-right">
        <img src={require("./img/package.jpg")} alt="experience" />
      </div>
     </section>

    <section class="section__container benefits__container">
      <h2 class="section__header">Benefits you get when using our services</h2>
      <div class="benefits__grid">
        <div class="benefits__card" data-aos="zoom-in">
        <h4>Reliability and Efficiency:</h4>
        <span><FaRegClock style={{ fontSize: 30 }}/></span>
          <div class="benefits__content">
            <p>Decka movers strives to deliver timely and efficient moving services, ensuring that customers' belongings are handled with the utmost care and delivered to their new destinations safely.
          </p>
           
          </div>
        </div>
        <div class="benefits__card" data-aos="zoom-in">
        <h4>Customer-Centric Approach:</h4>
        <span><FaHandshake style={{ fontSize: 30 }}/></span>
          <div class="benefits__content">
            <p>
            The company's focus is on understanding each customer's unique needs and preferences. KenyaMove Logistics aims to create personalized moving solutions that cater to different requirements, making the entire process convenient and stress-free.
           </p>
            
          </div>
        </div>
        <div class="benefits__card" data-aos="zoom-in">
          <h4>Professionalism and Expertise:</h4>
          <span><FaRegCheckCircle style={{ fontSize: 30 }}/></span>
          <div class="benefits__content">
            <p>
             We takes pride in its team of highly skilled and trained professionals who possess deep knowledge of the logistics and moving industry. The company is committed to continuously enhancing its expertise to adapt to changing trends and technologies.
           </p>
            
          </div>
        </div>
      </div>
    </section>

     

   
    <section class="section__container experience__container">
      <div class="experience__images" data-aos="fade-right">
        <img src={require("./img/sofa.jpeg")} alt="experience" />
      </div>
      <div class="experience__content" data-aos="fade-up">
        <h2 class="section__header">We provide you the best experience</h2>
        <strong>Vision:</strong>
        <p class="experience__description">
         We envisions a future where moving is no longer a daunting and stressful
         experience, but rather a smooth and exciting journey for individuals and businesses alike.
          The company aims to be the leading and most trusted moving and logistics partner
         in Kenya, setting new standards for quality, reliability, and customer satisfaction.
        </p>
        <strong>Mission:</strong>
        <p class="experience__description">
       Our mission is to redefine the moving experience by providing 
        comprehensive and tailored solutions that exceed customer expectations. The company is
         dedicated to continues to expand and innovate, it remains committed to providing exceptional moving experiences and shaping the future of relocation in Kenya.
       
         </p>
      </div>

         
     
    </section>
    
    <section class="section__container experience__container">
          
          <div className="all-review">
          <h2 class="section__header">Our Clients</h2>
          <div class="experience__col" data-aos="zoom-in">
                 <p>We have moved over</p>
                 <h3>50+</h3>
                 <p>Companies</p>
               </div>
          </div>
      
           <div className="carousel">
           <Slider {...settings}>
             <div className="box">
                <img src={require("./img/client1.png")} alt="experience" />
             </div>
             <div className="box">
               <img src={require("./img/client3.jpg")} alt="experience" />
             </div>
             <div className="box">
              <img src={require("./img/client6.png")} alt="experience" />
             </div>
             <div className="box">
              <img src={require("./img/client2.jpg")} alt="experience" />
             </div>
             <div className="box">
             <img src={require("./img/client5.jpg")} alt="experience" />
             </div>
             <div className="box">
             <img src={require("./img/client4.png")} alt="experience" />
             </div>
             <div className="box">
             <img src={require("./img/client7.png")} alt="experience" />
             </div>
             </Slider>
           </div>
           
         
          </section>
     

    {/* <p> In just a few years since its inception, KenyaMove Logistics has grown into a prominent player in the moving and logistics sector in Kenya. The company's dedication to its vision and mission has enabled it to build lasting relationships with customers and partners, making it a trusted name in the industry. As KenyaMove Logistics continues to expand and innovate, it remains committed to providing exceptional moving experiences and shaping the future of relocation in Kenya.
       </p> */}
    <Footer />
  </div> );
}
 
export default About;