import Footer from "./footer";
import React, { useState } from 'react';
import "./App.css";
import { FaEnvelope, FaMapMarkerAlt, FaPhoneAlt } from "react-icons/fa";


const dataCollection = [
  {
    question: '1. Q: What services does your moving company offer?',
    answer: 'We provide a comprehensive range of moving services, including local and long-distance moves, residential and commercial relocations, packing and unpacking, loading and unloading, as well as secure storage solutions. '
  }, {
    question: '2. Q: How do I request a moving quote?',
    answer: 'A: You can request a moving quote by filling out our online form on our website or by contacting our customer service team. We will gather necessary information to provide you with an accurate estimate. '
  }, {
    question: '3. Q: Is your moving company licensed and insured?',
    answer: 'A: Yes, we are a licensed and insured moving company. Our team and your belongings are fully covered, ensuring a safe and reliable moving experience. '
  },
  {
    question: '4. Q: Can I choose a specific moving date?',
    answer: " A: Absolutely, we strive to accommodate your preferred moving date. However, availability may vary, so it's best to schedule your move as early as possible."
  }, {
    question: '5. Q: How far in advance should I book my move?',
    answer: 'A: We recommend booking your move at least 4 to 6 weeks in advance, especially during peak moving seasons, to secure your desired date and ensure smooth logistics.'
  },
  {
    question: '6. Q: Do you offer packing services, and can I pack my own belongings?',
    answer: 'A: Yes, we offer professional packing services to ensure your items are packed securely. If you prefer to pack your belongings yourself, we can provide guidance and supply you with quality packing materials. '
  }, {
    question: '7. Q: Can I track the progress of my move?',
    answer: 'A: Yes, you can track the progress of your move by reaching out to our customer service team. We will provide updates on the status of your move and address any concerns.'
  },
  {
    question: '8. Q: How are fragile or valuable items handled during the move?',
    answer: ' A: We take special care of fragile and valuable items. Our trained professionals use appropriate packing materials and techniques to ensure the safe transportation of such items.'
  }, {
    question: '9. Q: Can you assist with international moves from Kenya to other countries?',
    answer: 'A: Yes, we offer international moving services, including customs clearance, documentation, and coordination with trusted overseas partners to ensure a seamless international relocation.'
  },
  {
    question: '10. Q: What happens if there is a delay or an issue during the move?',
    answer: 'A: In the rare event of a delay or issue, our customer service team will keep you informed and work diligently to resolve the situation promptly, minimizing any disruptions to your moving plans. '
  }, {
    question: '11. Q: Are there items that cannot be transported by your company?',
    answer: 'A: Certain hazardous materials, perishable items, and illegal substances cannot be transported by our company. Our team will provide you with a list of prohibited items and offer guidance on proper disposal.'
  },
  {
    question: '12. Q: How can I pay for the moving services?',
    answer: 'A: We accept various payment methods, including mpesa, bank transfers, and cash. Payment details and options will be discussed during the booking process. '
  }, {
    question: '13. Q: What should I do if I need to change my moving date?',
    answer: "A: If you need to change your moving date, please contact our customer service team as soon as possible. We'll do our best to accommodate your request and adjust the logistics accordingly."
  }, {
    question: '14. Q: Are pets allowed during the move?',
    answer: 'A: Yes, we understand that pets are part of the family. We can provide guidance on safe transportation of pets and recommend measures to ensure their comfort during the move.'
  }, {
    question: '15. Q: Can I leave clothes in my drawers during the move?',
    answer: "A: For safety and efficiency, we recommend removing items from drawers before the move. However, lightweight clothing items can often be left in drawers if they are secure and won't shift during transit. "
  }
];
const Contacts = () => {

  const [accordion, setActiveAccordion] = useState(-1);

  function toggleAccordion(index) {
    if (index === accordion) {
      setActiveAccordion(-1);
      return
    }
    setActiveAccordion(index);
  };
    return ( 
    <div className="contacts">
      <section class="section__container benefits__container">
      <h2 class="section__header">Contact us</h2>
      <div class="benefits__grid">
        <div class="benefits__card">
        <h4>Call us On:</h4>
          <span><FaPhoneAlt style={{ fontSize: 30 }}/></span>
          <div class="benefits__content">
           
            <p>
              +254 06557725 |  +254 06557725
            </p>
          
          </div>
        </div>
        <div class="benefits__card">
          <h4>Email Us on:</h4>
          <span><FaEnvelope style={{ fontSize: 30 }}/></span>
          <div class="benefits__content">
           
            <p>
            moversdecka@gmail.com 
            </p>
            <p>
            deckamovers@outlook.com
            </p>
          </div>
        </div>
        <div class="benefits__card">
        <h4>locate us:</h4>
          <span><FaMapMarkerAlt style={{ fontSize: 30 }}/></span>
          <div class="benefits__content">
            
            <p>
            Gandhi Avenue (opposite national oil)
            </p>
          </div>
        </div>
      </div>
    </section>




      <section className="section__container process__container">
      <div class='experience__description'>
        <h2 class="section__header">Locate us.</h2>
        <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d6398.642147572635!2d36.8233214305598!3d-1.3070562858320678!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x182f1113ee18e2bf%3A0xac70c6274666d5ae!2sDecka%20movers!5e0!3m2!1sen!2ske!4v1704652853537!5m2!1sen!2ske"
        width="350" height="600" 
        style={{border:"0"}} allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
      </div>
      <div class="process__content fate">
       <span className="accordion__title">Frequently asked questions</span>
        <h2 class="section__header">Let's answer some of your questions</h2>
        <div class="experience__description">
        <div className="accordion__faq">
          { dataCollection.map((item, index) =>
              <div key={index} onClick={() => toggleAccordion(index)}>
                <div className="accordion__faq-heading">
                  <h3 className={accordion === index ? "active" : ""}>{item.question}</h3>
                  <div>
                    {accordion === index ?
                      <span className="verticle">-</span> : <span className="horizental">+</span>}
                  </div>
                </div>
                <div><p className={accordion === index ? "active" : "inactive"} >{item.answer}</p></div>
              </div>
                    )
                  }
                </div>
              </div>
        
        </div>
     
     </section>
       
        <Footer />
    </div>
    );
  }
  
export default Contacts;