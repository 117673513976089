import { FaFacebook, FaInstagram, FaEnvelope, FaMapMarkerAlt, FaPhoneAlt } from "react-icons/fa";
import "./footer.css";
import React from 'react';


const Footer = () => {
    return (

        <div >
         <div className="footer">
            <div className="container">
                <div className="sec about-us">
                    <h2>About us</h2>
                    <p> Decka mover is a premier moving company based in Nairobi, Kenya, was founded in
           2017 with the vision of revolutionizing the moving and logistics industry
                    </p>
                    <ul className="socials">
                        <li>
                        <a href= "https://www.instagram.com/decka_move"> <FaInstagram style={{ fontSize: 25}} /></a> 
                        <a href= "https://www.facebook.com/profile.php?id=61556203834783"> <FaFacebook style={{ fontSize: 25}} /></a>
                        
                        
                        </li>                   
                    </ul>
                </div>
                <div className="sec worktime">
                    <h2>Working Hours</h2>
                    <p>
                    We work all days a week with exception on holidays, Do contact us for any inquiry.
                    </p>
                    
                    <p> Mon - Fri: 5.00 am - 10.00 pm</p>
                    <p> Saturday: 7.00 am - 10.00 pm</p>
                    <p>Sunday: 7.00 am - 10.00 pm
                    </p>
                </div>
                <div className="sec worktime final">
                    <h2>Locate Us</h2>                 
                    <p> <span><FaMapMarkerAlt style={{'color':'#fff'}}/></span> Gandhi Avenue (opposite national oil)</p>
                    <p><FaEnvelope style={{'color':'#fff'}}/>  moversdecka@gmail.com </p>
                    <p><FaPhoneAlt style={{'color':'#fff'}}/>   +254 06557725 </p>
                </div>
            </div>
            
            <div className="copyright"> 
             <hr class="visible-md visible-lg"></hr>
            </div><div class="dis35 visible-md visible-lg"></div> 
            <p>copyright © 2023 Responsive . All Rights Reserved | Built by foshi2</p>            
            </div> 
            </div> 
            
     );
}
 
export default Footer;