import React, { useState } from "react";
import { Link, useHistory } from "react-router-dom";
import "./Data/form.css";
import "./service.css";

const Office = () => {
  const formInitialDetails = {
    user: "",
    email: "",
    phno: "",
    location: "",
    date: ""
  };

  const [formValue, setFormValue] = useState(formInitialDetails);
  const [formErrors, setFormErrors] = useState({});
  const [isSubmit, setIsSubmit] = useState(false);
  const history = useHistory();

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormValue({ ...formValue, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setFormErrors(validate(formValue));
    setIsSubmit(true);

    if (Object.keys(formErrors).length === 0) {
      try {
        await fetch('/api/metadata', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify(formValue)
        });
        setFormValue(formInitialDetails);
        history.push("/success");
      } catch (error) {
        console.error('Error submitting form:', error.message);
        // Handle error state or display an error message to the user
      }
    }
  };

  const validate = (values) => {
    const errors = {};
    const regex = /^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/i;
    if (!values.user) {
      errors.user = "Username is required!";
    }
    if (!values.location) {
      errors.location = "Your location is required!";
    }
    if (!values.email) {
      errors.email = "Email is required!";
    } else if (!regex.test(values.email)) {
      errors.email = "This is not a valid email format!";
    }
    if (!values.phno) {
      errors.phno = "Your Phone number is required";
    } else if (values.phno.length !== 10) {
      errors.phno = "Your Phone number must be exactly 10 characters";
    }
    return errors;
  };

  return (
    <div className="section_choice">
      <div className="choice">
        {Object.keys(formErrors).length === 0 && isSubmit ? (
          <div className="ui-message-success">Booking completed successfully</div>
        ) : (
          <div className="ui-message-success">Get a booking now!</div>
        )}

        <form onSubmit={handleSubmit}>
          <div className="grid">
            <div className="icon">
              <label><p>Companies name</p></label>
              <input
                name="user"
                type="text"
                value={formValue.user}
                onChange={handleChange}
              />
              <p>{formErrors.user}</p>

              <label><p>Your Email Address</p></label>
              <input
                type="text"
                name="email"
                value={formValue.email}
                onChange={handleChange}
              />
              <p>{formErrors.email}</p>

              <label><p>Moving Date</p></label>
              <input
                type="date"
                name="date"
                value={formValue.date}
                onChange={handleChange}
              />
            </div>
            <div className="icon">
              <label><p>Your Location</p></label>
              <input
                type="text"
                name="location"
                value={formValue.location}
                onChange={handleChange}
              />
              <p>{formErrors.location}</p>

              <label><p>Your Phone Number</p></label>
              <input
                type="text"
                name="phno"
                value={formValue.phno}
                onChange={handleChange}
              />
              <p>{formErrors.phno}</p>
            </div>
          </div>
          <div className="my_choice more">
            <div className="icon too">
              <Link to="./about">
                <button> Previous </button>
              </Link>
            </div>
            <div className="icon too">
              <button type="submit"> Next </button>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
}

export default Office;
