import React from 'react';
import Footer from './footer';

const App = () => {
  return (
    <div className="app">
      <section class="section__container process__container">        
         <div class="process__content" data-aos="zoom-in">
           <h2 class="section__header">Pre-Move Phase:</h2>
           <p class="experience__description">
          <strong>1.Online Moving Request:</strong> <p>The process begins with you requesting a moving quote by filling out our online form on our website or by contacting our customer service team. We will gather necessary information to provide you with an accurate estimate. </p> 

          <strong>2.Customized Plan:</strong>  <p> Based on the consultation, a personalized moving plan is created. The plan outlines the scope of work, timeline, packing materials needed, and any additional services requested.</p> 

          <strong>3.Quotation:</strong> <p>  A detailed, transparent quotation is provided to the client, including all costs involved – packing, transportation, labor, and any specialized services.</p> 

          <strong>4.Scheduling:</strong>  <p> Once the client approves the quotation, a mutually convenient moving date is scheduled.</p> 

          <strong>5.Packing Preparation:</strong>  <p>  The client receives guidance on how to prepare for packing day, including decluttering tips and packing guidelines.</p> 
           </p>           
         </div>
   
         <div class="experience__image" data-aos="zoom-in">
            <img src={require("./img/lorry2.jpeg")} alt="experience" />
          </div>
        </section>

        <section class="section__container process__container">        
         <div class="process__content" data-aos="zoom-in">
           <h2 class="section__header">On-Moving Day:</h2>
          
           <p class="experience__description">
          <strong>1.Professional Packing: </strong> <p>Our skilled packing team arrives with high-quality packing materials. They carefully pack items, ensuring delicate and valuable items are well-protected.</p> 

          <strong>2.Inventory Management: </strong>  <p>A comprehensive inventory list is created, detailing each item and its condition before packing. This helps ensure accountability during the move. </p> 

          <strong>3.Loading and Transportation: :</strong> <p> Packed items are loaded onto specialized moving vehicles, optimized for secure transportation. Our drivers are experienced in navigating Kenyan roads and ensuring timely delivery. </p> 

          <strong>4.Communication: </strong>  <p>Clients are provided with real-time updates on the progress of their move, maintaining transparency and addressing any concerns promptly. </p>  
           </p>           
         </div>
   
         <div class="experience__image" data-aos="zoom-in">
            <img src={require("./img/cooker.jpeg")} alt="experience" />
          </div>
        </section>
        <section class="section__container process__container">        
         <div class="process__content"data-aos="zoom-in">
           <h2 class="section__header">Post-Move Phase:</h2>
          
           <p class="experience__description">
          <strong>1.Unpacking:</strong> <p>Upon arrival at the destination, our team unpacks and assembles furniture, placing items according to the client's preferences. Fragile items are handled with care.</p> 

          <strong>2.Quality Check:  </strong> <p>Our team conducts a thorough inspection to ensure all items have been safely delivered and are in good condition. The client verifies the inventory list and can report any discrepancies.  </p> 

          <strong>3.Debris Removal:</strong> <p> All packing materials and debris are removed, leaving the new space clean and ready for the client to settle in.</p> 

          <strong>4.Client Satisfaction:</strong>  <p>We value client feedback and ensure their satisfaction with the entire moving process. Any concerns are addressed promptly to ensure a positive experience. </p>  
          <strong>5.Follow-Up:</strong>  <p> A follow-up communication is initiated to ensure the client is fully settled and content with their new space. Any additional assistance needed is provided. </p> 
          <strong>6.Community Support:</strong>  <p>As a responsible moving company, we contribute to the local community by recycling packing materials and engaging in social initiatives. </p> 
          <strong>7.Documentation: </strong>  <p>The entire moving process is documented, ensuring accountability and a smooth experience for future moves. </p> 
           </p>           
         </div>
   
         <div class="experience__image"data-aos="zoom-in">
            <img src={require("./img/fridge.jpeg")} alt="experience" />
          </div>
        </section>
        <Footer />
    </div>
  );
};

export default App;
