import { BrowserRouter as Router, Route, Switch } from 'react-router-dom'
import About from './About'
import Contacts from './Contacts'
import Home from './Home'
import Navbar from './Navbar'
import Process from './Process'
import Services from './Service'
import Confrim from './Confrim'
import React, {useEffect} from 'react';




function App() {
 

  return (
    <div className="App">
      
      <Router>
      
        <Navbar />
     
          <Switch>
            <Route exact path="/">
              <Home />
            </Route>
            <Route path="/about">
              <About />
            </Route>
            <Route path="/services">
              <Services />
            </Route>
            <Route path="/process">
              <Process />
            </Route>
            <Route path="/contacts">
              <Contacts />
            </Route>
            <Route path="/success">
              <Confrim />
            </Route>
          </Switch>
      
    </Router>
    </div>
  )
}

export default App
