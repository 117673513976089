import React from 'react';
import "./service.css";
import { FaEnvelope, FaPhoneAlt, FaArrowLeft, FaArrowRight, FaRegCheckCircle  } from "react-icons/fa";
import { Link } from "react-router-dom";


const Confrim = () => {
    return ( 
    <div className="sect">
     <div className="sect-c">
      <div className="image">
      <span><FaRegCheckCircle style={{ fontSize: 50 }}/></span>
      </div>
      <h3>Confirmed</h3>
      <p>our customer service team will provide the moving cost and update you on the status of your move via: </p>
       <div className="ss">
       <p><FaEnvelope style={{'color':'#fff'}}/>  moversdecka@gmail.com</p>
        <p><FaPhoneAlt style={{'color':'#fff'}}/>  +254 06557725 </p>
     
       </div>
      <p><FaArrowLeft /> <Link to="./" >Go back to homepage</Link> </p>
      <p><Link to="./contacts" >Do you have some questions </Link><FaArrowRight /></p>  
        </div>
    </div>
 
     );
}
 
export default Confrim;